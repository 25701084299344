<template>
  <v-dialog v-model="showDialog" width="1400">
    <v-card>
      <v-card-title>
        <v-col>
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Integracija s dobavljačem</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>

          <v-row></v-row> <v-row></v-row> <v-row></v-row> <v-row></v-row>
          <v-row>
            <v-btn
              style="z-index: 4"
              rounded
              left
              color="primary"
              @click="addIntegration"
            >
              <v-icon>mdi-plus</v-icon>
              <span>{{ $t(`$vuetify.company.addIntegration`) }}</span>
            </v-btn>
          </v-row>
        </v-col>

        <add-integration
          ref="addIntegration"
          @success="refreshIntegrations"
        ></add-integration>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row style="margin-top: 20px" class="justify-center">
            <v-col cols="12" :class="['px-0']">
              <v-card
                v-for="(int, i) in integrations"
                :key="i"
                hover
                tabindex="0"
                class="mb-4"
              >
                <v-row>
                  <v-col cols="3" :class="[densityPadding]">
                    <div class="caption grey--text text-center">Lokacija</div>
                    <div class="text-center">{{ int.location_name }}</div>
                  </v-col>

                  <v-col
                    cols="2"
                    v-if="supplier.integration_type !== 'roto'"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      Tip komunikacije
                    </div>
                    <div class="text-center">{{ int.integration }}</div>
                  </v-col>
                  <v-col
                    cols="5"
                    v-if="supplier.integration_type !== 'roto'"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">Email</div>
                    <div class="text-center">{{ int.email }}</div>
                  </v-col>
                  <v-col
                    cols="2"
                    v-if="supplier.integration_type !== 'roto'"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      Tip dokumenta
                    </div>
                    <div class="text-center">{{ int.type }}</div>
                  </v-col>
                  <v-col
                    cols="2"
                    v-if="supplier.integration_type === 'roto'"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      Korisničko ime
                    </div>
                    <div class="text-center">{{ int.username }}</div>
                  </v-col>
                  <!-- <v-col
                    cols="2"
                    v-if="supplier.integration_type === 'roto'"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                     Lozinka
                    </div>
                    <div>
                      {{ int.password}}
                    </div>

                  </v-col> -->
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <infinite-loading
            ref="InfiniteLoading"
            @infinite="infiniteHandler"
            spinner="waveDots"
          >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </v-container> </v-card-text></v-card
  ></v-dialog>
</template>
<script>
import AddIntegration from '@/modules/company/components/suppliers/integration/AddIntegration'
import { df } from '@/plugins/firebase'
import state from '@/state'
import { clone } from '@/plugins/utils'
import InfiniteLoading from 'vue-infinite-loading'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'

export default {
  components: {
    InfiniteLoading, AddIntegration
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader'

  ],
  data: function () {
    return {
      loading: false,
      lastVisible: '',
      listeners: [],
      multiplier: 0,
      integrations: [],
      supplier: undefined,
      location: undefined,
      showDialog: false
    }
  },
  mounted () {
    this.getMultiplier()
  },
  watch: {
    showDialog (nv) {
      if (!nv) {
        this.integrations = []
        this.close()
      }
      if (nv) {
        this.reset()
      }
    }
  },
  methods: {
    reset () {
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    close () {
      this.lastVisible = ''
      this.detachListeners()
      this.showDialog = false
    },
    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },
    open (supplier, location) {
      this.supplier = supplier
      this.location = location

      this.showDialog = true
    },
    refreshIntegrations (integration) {
      this.integrations.push(
        { ...integration, ...{ status: 'PROCESSING' } }
      )
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    infiniteHandler ($state) {
      if (this.supplier === undefined) {
        return
      }
      const that = this
      const query = df.collection(`suppliers/${this.supplier.id}/integrations`)
        .where('company_id', '==', state.getCurrentCompany().id)
        .orderBy('location_id')
        .limit(10)
        .startAfter(that.lastVisible)
      const listener = query.onSnapshot((doc) => {
        doc.docChanges().forEach((change) => {
          that.modifyintegrations(change)
        })
        if (doc && !doc.empty) {
          if (doc.docs[doc.docs.length - 1]) {
            that.lastVisible = doc.docs[doc.docs.length - 1]
          }
          $state.loaded()
        } else {
          $state.complete()
        }
      })
      this.listeners.push(listener)
    },
    modifyintegrations (change) {
      if (change.type === 'added') {
        if (change.doc.data()) {
          const _document = clone(change.doc.data())

          var locId = _document.location_id
          df.doc(`locations/${locId}`)
            .get()
            .then((locDoc) => {
              if (locDoc && locDoc.data()) {
                var loc = locDoc.data()

                _document.location_name = loc.name
              }

              this.integrations.push({
                ..._document,
                ...{ [`hover-${change.doc.data().id}`]: false }
              })
              // }

              this.integrations.sort((a, b) => a.name - b.name) // b - a for reverse sort
              this.$forceUpdate()
            })
        }
      }
      if (change.type === 'modified') {
        this.integrations = this.integrations.map(int => {
          let ret = int
          if (int.id === change.doc.data().id) {
            const _document = clone(change.doc.data())

            ret = _document
          }
          return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
        })
      }
    },
    addIntegration () {
      this.$refs.addIntegration.open(this.supplier)
    }
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
